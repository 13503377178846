import React, {
    Fragment,
    useState,
    useCallback,
    useContext,
  } from 'react'  
import { navigate } from "gatsby"
import {
    Page,
    Layout,
    Card,
    TextField,
    Subheading,
    DisplayText,
    Icon,
    Stack,
    ButtonGroup,
    Button,
    InlineError,
    TextContainer,
    Badge,
    CalloutCard,
    Heading,
    TextStyle,
    FormLayout,
  } from "@shopify/polaris"
import {
    ExternalSmallMinor,
    QuestionMarkMajor
  } from '@shopify/polaris-icons';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc } from 'firebase/firestore';
import FirebaseContext from '../../../providers/firebase';
import styled from 'styled-components'
import CreateOfferSteps from './createOfferSteps'
const CartOfferPreviewWrapper = styled.div`
    padding: 50px 0 10px 0;
    .preview-button {
        padding: 25px 0 15px 0;
        .Polaris-Button {
            width: 485px;
        }
    }
    .instructions {
        display: flex;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        max-width: 575px;
        margin: auto;
        .instructions-image {
            margin-bottom: 20px;
            padding: 10px;
            div {
                img {
                    max-width: 125px;
                }
            }
        }
    }
    .action-buttons {
        padding: 15px 0 25px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
`
const ButtonsWrapper = styled.div`
    margin-top: 15px;
`
const OfferButtonWrapper = styled.div`
  margin-top: 15px;
  text-align: center;
  button {
            color: #fff;
            background: #AEB4B9;
        }
  button:hover {
    background: #AEB4B9;
  }
`
function CartDrawerOfferPreview(props) {
    const { location } = props
    const { firebase, shop, token } = useContext(FirebaseContext);
    const [active, setActive] = useState(false)
    const shopDoc = doc(firebase.firestore, 'shops', shop);
    const [shopData, shopDataLoading, shopDataError] = useDocumentData(
        shopDoc,
        {
            transform: (snap) => snap.shopData
        }
    );
    const domain = shopData && shopData.domain
    return (
        <Page 
      breadcrumbs={[{content: 'Offers', url: '/app/offers'}]}
      title={
        `Create Offer`
      }
      //primaryAction={{content: 'Save'}}
    >
        <Layout>
            <Layout.Section>
                <div style={{margin: '20px 0 10px -25px'}}>
                    <CreateOfferSteps step={3}/>
                </div>
                <Card>
                <Card.Section>
                <CartOfferPreviewWrapper>
                    <div className='instructions'>
                        <div className="instructions-image">
                            <div>
                                <img src="https://firebasestorage.googleapis.com/v0/b/upsell-shopify.appspot.com/o/cart-drawer-preview-icon.png?alt=media&token=edf63b96-8e47-443a-9019-6d8b1f6d19c2" />
                            </div>
                        </div>
                        <FormLayout>
                            <TextContainer spacing="loose">
                                <DisplayText size="medium">Connect your theme to UpsellPlus</DisplayText>
                                <div>
                                <TextStyle variation="subdued">This feature requires a technical setup. Request our support or follow the guide.</TextStyle>
                                </div>
                            </TextContainer>
                        </FormLayout>
                    </div>
                    <div className='action-buttons'>
                          <ButtonsWrapper>
                          <Stack distribution="center">
                                <ButtonGroup>
                                    
                                    <Button primary={true} onClick={
                                            () => {
                                                if(window && window.$crisp){
                                                    window.$crisp.push(["do", "chat:open"]);
                                                } 
                                            }
                                        }>
                                        <Stack distribution="center" alignment='center' spacing='tight'>
                                        <span>Request setup</span>
                                        <Icon
                                        source={ExternalSmallMinor}
                                        color="base"
                                        />
                                    </Stack>
                                    </Button>
                                    <Button  primary={true} onClick={
                                        () => {
                                            window.open(`https://help.upsellplus.com/en/article/how-to-get-started-cart-upsells-cart-drawer-apfde9/`, '_blank')
                                        }
                                    }>
                                        <Stack distribution="center" alignment='center' spacing='tight'>
                                        <span>To setup guide</span>
                                        <Icon
                                        source={ExternalSmallMinor}
                                        color="base"
                                        />
                                    </Stack>
                                    </Button>
                                    
                                </ButtonGroup>
                                
                            </Stack>
                            </ButtonsWrapper>
                            <OfferButtonWrapper>
                                    <Button  onClick={
                                            () => {
                                                navigate('/app/offers', { replace: true,})
                                            }
                                        }>To offer overview</Button>
                            </OfferButtonWrapper>
                    </div>
                </CartOfferPreviewWrapper>
                </Card.Section>
                </Card>
            </Layout.Section>
        </Layout>
    </Page>
   
    )
}

export default CartDrawerOfferPreview;